import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'

import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import Models from '@solid-ui-blocks/Modeller/Models'
import httpService from '../../service/httpService';
import loadsGif from '../assets/loads.gif'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const KutuTasarimi = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const [modelListesi, setList] = useState([]);

  const [ldg, setLdg] = useState(false);

  useEffect(() => {
    setLdg(false);
    httpService.post("UnLocked/ModelList", {}).then((res) => {
      if (res.DogruMu) {
        setList(res)
        setLdg(true);
      } else {

      }
    }).catch((err) => {
      setLdg(true);
    });
  }, [])

  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Ambalaj Modelleri Online Vectorel Çizim Diecutting Dieline Generator' />
      <Divider space='5' />
      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <Models content={modelListesi} pageR={{ pageR: "tum" }} ckid={{ ckid: 0 }} />
      }
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageBoxDesignBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default KutuTasarimi
